.label {
  display: inline-flex;
  flex-direction: column;
  align-items: start;
  margin: 0.5rem;
}

.input {
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 300px;
}
