.label {
  margin-right: 0.5rem;
  user-select: none;
  cursor: pointer;
}

.checkbox {
  width: 1.5rem;
  height: 1.5rem;
}
