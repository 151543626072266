.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30%;
  left: 10%;
  width: 80%;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  z-index: 100;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.modal-content {
  margin-bottom: 10px;
}

.modal-close {
  cursor: pointer;
  color: #888;
  font-size: 1.5em;
  background: none;
  border: none;
  padding: 0;
}

.modal-body {
  padding: 20px;
}
